import React from "react";
import BlogList from "../components/BlogList";
import Pagination from "../components/Pagination";
import SEO from "../components/seo";
import Layout from "../components/Layout";
import { graphql } from "gatsby";

class BlogPageTemplate extends React.Component {
  render() {
    return (
      <div className="slide-up">
        <div
          className="center margin-top-2"
          style={{
            marginTop: "3em",
          }}
        ></div>
        <section className="section section--gradient">
          <div className="container">
            <div className="content">
              <BlogList posts={this.props.posts} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const BlogPage = (props) => {
  const { edges: posts } = props.data.allMarkdownRemark;
  const { currentPage, numPages } = props.pageContext;
  return (
    <Layout>
      <SEO
        title="Tutorials on frontend, backend development and frameworks."
        description="Become a better developer. Learn through practical and in-depth tutorials about frontend, backend development and frameworks all at one place."
      />
      <BlogPageTemplate posts={posts} />
      <Pagination currentPage={currentPage} numPages={numPages} path={"/"} />
    </Layout>
  );
};

export default BlogPage;

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          timeToRead
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            date(formatString: "DD MMM, YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
