import { Link } from "gatsby";
import React from "react";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

function BlogList({ posts }) {
  return (
    <>
      <div className="grid grid-3 blog-grid">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-12" key={post.id}>
              <article className="blog-list-item">
                <header className="flex flex-col-around">
                  {post.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : null}

                  <p className="post-meta">
                    <span className="subtitle is-block blogRollDate">
                      {post.frontmatter.date} &bull; {post.timeToRead} min
                    </span>
                    <Link
                      className="title has-text-primary is-size-5 blogRollTitle"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                  </p>
                </header>

                <p className="blogRollExcerpt flex flex-col-around">
                  {post.frontmatter.description}
                </p>
                <Link className="button is-primary" to={post.fields.slug}>
                  Keep Reading →
                </Link>
              </article>
            </div>
          ))}
      </div>
    </>
  );
}
// const ClipText = (data, length) => {
//   return data.slice(0, length) + "...";
// };
export default BlogList;
