import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PreviewCompatibleImage = ({ imageInfo }) => {
  const imageStyle = { width: "100%", margin: "0 0 1em 0" };
  const { alt = "", childImageSharp, image } = imageInfo;

  if (!!image && !!image.childImageSharp) {
    const img = getImage(image.childImageSharp.gatsbyImageData);

    return <GatsbyImage style={imageStyle} image={img} alt={alt} />;
  }

  if (!!childImageSharp) {
    const img = getImage(childImageSharp.gatsbyImageData);

    return <GatsbyImage style={imageStyle} image={img} alt={alt} />;
  }

  if (!!image && typeof image === "string") {
    const img = getImage(image);
    return <GatsbyImage style={imageStyle} image={img} alt={alt} />;
  }

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
};

export default PreviewCompatibleImage;
