import React from "react";
import { Link } from "gatsby";

class Pagination extends React.Component {
  render() {
    const { currentPage, numPages, path } = this.props;
    let ellipsed = false;
    let shouldEllipse = false;
    const paginatedViews = [];
    // console.log("num" + numPages);
    Array.from({ length: numPages }).forEach((_, i) => {
      const pageNumber = i + 1;
      const right = numPages - pageNumber;
      let showNumber = pageNumber <= 3 || right === 0;
      showNumber =
        showNumber ||
        (pageNumber > currentPage - 2 && pageNumber < currentPage + 2);

      if (showNumber) {
        ellipsed = false;
        shouldEllipse = false;
        paginatedViews.push(
          <li
            key={`pagination-number${pageNumber}`}
            className={pageNumber === currentPage ? "selected" : null}
          >
            <Link
              className="pagination-link"
              to={`${path}${
                pageNumber === 1 ? "blog/" : `blog/page/` + pageNumber
              }`}
            >
              {i + 1}
            </Link>
          </li>
        );
      } else {
        shouldEllipse = !ellipsed;
        ellipsed = true;

        if (shouldEllipse) {
          paginatedViews.push(
            <li
              key={`pagination-number${pageNumber}`}
              className="disabled pagination-ellipsis"
            >
              <span aria-hidden="true">&hellip;</span>
            </li>
          );
        }
      }
    });

    return (
      <nav class="pagination is-centered" role="navigation">
        <ul className="pagination-list pagination-container">
          <li>Pages</li>
          {paginatedViews}
        </ul>
      </nav>
    );
  }
}

export default Pagination;
